import { FunctionComponent, useMemo, type CSSProperties } from "react";
import { useNavigate } from 'react-router-dom';
import styles from "./NavigationContainer.module.css";

type NavigationContainerType = {
  icon?: string;
  vector?: string;

  /** Style props */
  navigationContainerFlexShrink?: CSSProperties["flexShrink"];
  ourSolutionColor?: CSSProperties["color"];
  ourPlatformColor?: CSSProperties["color"];
  ourPropertyColor?: CSSProperties["color"];
  backgroundStyle?: CSSProperties["backgroundColor"];
};

const NavigationContainer: FunctionComponent<NavigationContainerType> = ({
  icon,
  vector,
  navigationContainerFlexShrink,
  ourSolutionColor,
  ourPlatformColor,
  ourPropertyColor,
  backgroundStyle,
}) => {

  const navigate = useNavigate();

  const navigationContainerStyle: CSSProperties = useMemo(() => {
    return {
      flexShrink: navigationContainerFlexShrink,
      backgroundColor: (backgroundStyle) ? backgroundStyle : '#FFF'
    };
  }, [navigationContainerFlexShrink]);

  const ourSolutionStyle: CSSProperties = useMemo(() => {
    return {
      color: ourSolutionColor,
    };
  }, [ourSolutionColor]);

  const ourPlatformStyle: CSSProperties = useMemo(() => {
    return {
      color: ourPlatformColor,
    };
  }, [ourPlatformColor]);

  const ourPropertyStyle: CSSProperties = useMemo(() => {
    return {
      color: ourPropertyColor,
    };
  }, [ourPropertyColor]);

  return (
    <nav
      className={styles.navigationcontainer}
      style={navigationContainerStyle}
    >
      <div className={styles.icon}>
        <button onClick={() => { navigate('/') }}>
          <img className={styles.icon1} alt="" src={icon} />
        </button>
      </div>
      <div className={styles.menu}>
        <button className={styles.menuitemsolution} onClick={() => { navigate('/solution') }}>
          <b className={styles.ourSolution} style={ourSolutionStyle}>
            our solution
          </b>
        </button>
        <button className={styles.menuitemsolution} onClick={() => { navigate('/platform') }}>
          <b className={styles.ourSolution} style={ourPlatformStyle}>
            our platform
          </b>
        </button>
        <button className={styles.menuitemsolution} onClick={() => { navigate('/property') }}>
          <b className={styles.ourSolution} style={ourPropertyStyle}>
            our property
          </b>
        </button>
      </div>
      <div className={styles.socialicons}>
        <img className={styles.vectorIcon} alt="" src={vector} />
      </div>
    </nav>
  );
};

export default NavigationContainer;
