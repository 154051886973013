import { FunctionComponent } from "react";
import NavigationContainer from "../components/NavigationContainer";
import Card from "../components/Card";
import Quote from "../components/Quote";
import Footer from "../components/Footer";
import styles from "./Property.module.css";

const Property: FunctionComponent = () => {
  return (
    <div className={styles.property}>
      <NavigationContainer
        icon="/icon.svg"
        vector="/vector.svg"
        navigationContainerFlexShrink="0"
        ourSolutionColor="#fff"
        ourPlatformColor="#fff"
        ourPropertyColor="#fff"
        backgroundStyle="#111"
      />
      <div className={styles.header}>
        <div className={styles.herocontainer}>
          <div className={styles.herovisual} />
          <div className={styles.herocontent}>
            <b className={styles.ourProperty}>our property</b>
            <div className={styles.theUpcomingGeneration}>
              The upcoming generation is deeply committed to a healthier planet.
              WYCKX stands with this forward-thinking cohort, proactively
              shaping the future. We construct eco-friendly properties and
              motivate the new wave of inhabitants to lead the way in
              sustainable urban lifestyles.
            </div>
          </div>
        </div>
        <img
          className={styles.ribbonspacerIcon}
          alt=""
          src="/ribbonspacer2@2x.png"
        />
      </div>
      <div className={styles.cards}>
      <section className={styles.cardsframe}>
          <div className={styles.clmn}>
            <Card
              icon="/icon.svg"
              cardTitle="Co-ownership."
              cardContent="Now that large assets and investments can be translated into a representative set of efficiently tradable, small digital assets we can drastically innovate the financing, ownership and commercialization of property."
              showIcon
              showCommunityEconomics
              showInTheEra
              showButton={false}
              cardBackgroundColor="#111"
              communityEconomicsColor="#fff"
              inTheEraColor="#fff"
            />
          </div>
          <div className={styles.clmn1}>
            <Card
              icon="/icon.svg"
              cardTitle="Co-economics."
              cardContent="WYCKX introduces a new approach to property financing, ownership and commercialization through a new proprietary economic model built on Web3 principles and innovative technology; community economics."
              showIcon
              showCommunityEconomics
              showInTheEra
              showButton={false}
              cardBackgroundColor="#111"
              communityEconomicsColor="#fff"
              inTheEraColor="#fff"
            />
          </div>
        </section>
        <div className={styles.cardsframe1}>
          <div className={styles.logowyckx}>
            <img className={styles.logoIcon} alt="" src="/logo1.svg" />
          </div>
        </div>
        <section className={styles.cardsframe}>
          <div className={styles.clmn}>
            <Card
              icon="/icon.svg"
              cardTitle="Co-living."
              cardContent="WYCKX properties are created with the idea of co-living in mind to connect residents, digital nomads and pioneers. All members will be welcomed in a space that combines biophilia with features that reinforce community engagement, urban culture, mental health and a green lifestyle."
              showIcon
              showCommunityEconomics
              showInTheEra
              showButton={false}
              cardBackgroundColor="#e08f7c"
              communityEconomicsColor="#fff"
              inTheEraColor="#fff"
            />
          </div>
          <div className={styles.clmn1}>
            <Card
              icon="/icon1.svg"
              cardTitle="Property Development."
              cardContent="The upcoming generation is deeply committed to a healthier planet. WYCKX stands with this forward-thinking cohort, proactively shaping the future. We construct eco-friendly properties and motivate the new wave of inhabitants to lead the way in sustainable urban lifestyles."
              showIcon={false}
              showCommunityEconomics={false}
              showInTheEra={false}
              showButton
              cardBackgroundColor="#111"
              communityEconomicsColor="#000"
              inTheEraColor="#000"
            />
          </div>
        </section>
        <div className={styles.dividertitle}>
          <img className={styles.mixedUseIcon} alt="" src="/mixed-use.svg" />
          <div className={styles.mixedUse}>mixed use</div>
        </div>
        <section className={styles.cardsframe}>
          <div className={styles.clmn}>
            <Card
              icon="/icon.svg"
              cardTitle="Resident members."
              cardContent="Permanent residents who live in the building and therewith set an example in green living lifestyles."
              showIcon
              showCommunityEconomics
              showInTheEra
              showButton
              cardBackgroundColor="#111"
              communityEconomicsColor="#fff"
              inTheEraColor="#fff"
            />
          </div>
          <div className={styles.clmn1}>
            <Card
              icon="/icon.svg"
              cardTitle="Digital nomads."
              cardContent="Local and international traveling professionals who wish to temporarily stay and work in the WYCKX."
              showIcon
              showCommunityEconomics
              showInTheEra
              showButton={false}
              cardBackgroundColor="#111"
              communityEconomicsColor="#fff"
              inTheEraColor="#fff"
            />
          </div>
        </section>
        <section className={styles.cardsframe}>
          <div className={styles.clmn}>
            <Card
              icon="/icon.svg"
              cardTitle="Pioneers."
              cardContent="Start-up founders focused on sustainability and inclusion."
              showIcon
              showCommunityEconomics
              showInTheEra
              showButton={false}
              cardBackgroundColor="#111"
              communityEconomicsColor="#fff"
              inTheEraColor="#fff"
            />
          </div>
          <div className={styles.clmn1}></div>
        </section>
        <img
          className={styles.cardsframeIcon}
          alt=""
          src="/cardsframe3@2x.png"
        />
      </div>
      <Quote
        quote="Here’s to the WYCKED ones, the misfits, the rebels — the ones who see things differently ... because the ones who are crazy enough to think that they can change the world, are the ones who do."
        author="IN REFERENCE TO STEVE JOBS"
      />
      <Footer />
    </div>
  );
};

export default Property;
