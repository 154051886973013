import { FunctionComponent, useMemo, type CSSProperties } from "react";
import styles from "./Card.module.css";

type CardType = {
  icon?: string;
  cardTitle?: string;
  cardContent?: string;
  showIcon?: boolean;
  showCommunityEconomics?: boolean;
  showInTheEra?: boolean;
  showButton?: boolean;

  /** Style props */
  cardBackgroundColor?: CSSProperties["backgroundColor"];
  communityEconomicsColor?: CSSProperties["color"];
  inTheEraColor?: CSSProperties["color"];
};

const Card: FunctionComponent<CardType> = ({
  icon,
  cardTitle = "Community Economics.",
  cardContent = "In the era of blockchain, the concept of community ownership has gained unprecedented momentum. Blockchain, often synonymous with decentralization, offers a transparent, immutable, and secure foundation, challenging traditional models of ownership and governance.",
  showIcon,
  showCommunityEconomics,
  showInTheEra,
  showButton,
  cardBackgroundColor,
  communityEconomicsColor,
  inTheEraColor,
}) => {
  const cardStyle: CSSProperties = useMemo(() => {
    return {
      backgroundColor: cardBackgroundColor,
    };
  }, [cardBackgroundColor]);

  const communityEconomicsStyle: CSSProperties = useMemo(() => {
    return {
      color: communityEconomicsColor,
    };
  }, [communityEconomicsColor]);

  const inTheEraStyle: CSSProperties = useMemo(() => {
    return {
      color: inTheEraColor,
    };
  }, [inTheEraColor]);

  return (
    <div className={styles.card} style={cardStyle}>
      {showIcon && <img className={styles.icon} alt="" src={icon} />}
      {showCommunityEconomics && (
        <b
          className={styles.communityEconomics}
          style={communityEconomicsStyle}
        >
          {cardTitle}
        </b>
      )}
      {showInTheEra && (
        <div className={styles.inTheEra} style={inTheEraStyle}>
          {cardContent}
        </div>
      )}
      {showButton && (
        <div className={styles.button}>
          <b className={styles.buttontitle}>The saga continues</b>
        </div>
      )}
    </div>
  );
};

export default Card;
