import { FunctionComponent } from "react";
import NavigationContainer from "../components/NavigationContainer";
import Card from "../components/Card";
import SystemChangeCard from "../components/SystemChangeCard";
import Quote from "../components/Quote";
import Footer from "../components/Footer";
import styles from "./Solution.module.css";

const Solution: FunctionComponent = () => {
  return (
    <div className={styles.solution}>
      <NavigationContainer
        icon="/icon1.svg"
        vector="/vector1.svg"
        navigationContainerFlexShrink="0"
        ourSolutionColor="#000"
        ourPlatformColor="#000"
        ourPropertyColor="#000"
      />
      <div className={styles.header}>
        <div className={styles.herocontent}>
          <img
            className={styles.thinkTheOpposite}
            alt=""
            src="/think-the-opposite.svg"
          />
        </div>
      </div>
      <div className={styles.cards}>
      <section className={styles.cardsframe}>
          <div className={styles.clmn}>
            <Card
              icon="/icon.svg"
              cardTitle="Democratizing investments."
              cardContent="Retail investors to join the game of property development through the democratization of real-estate investment. A diverse group of stakeholders to collectively own, and yield interest on, a diversified portfolio of real-estate assets."
              showIcon
              showCommunityEconomics
              showInTheEra
              showButton
              cardBackgroundColor="#111"
              communityEconomicsColor="#fff"
              inTheEraColor="#fff"
            />
          </div>
          <div className={styles.clmn1}>
            <Card
              icon="/icon1.svg"
              cardTitle="For the next generation."
              cardContent="Next-gen urban residents to invest a piece of their monthly fees in property development; accumulating wealth by owning pieces of real-estate."
              showIcon
              showCommunityEconomics
              showInTheEra
              showButton={false}
              cardBackgroundColor="#fff"
              communityEconomicsColor="#000"
              inTheEraColor="#000"
            />
          </div>
        </section>
        <div className={styles.cardsframe1}>
          <div className={styles.logowyckx}>
            <img className={styles.logoIcon} alt="" src="/logo1.svg" />
          </div>
        </div>
        <div className={styles.dividertitle}>
          <img className={styles.oldNew} alt="" src="/old--new.svg" />
        </div>
        <div className={styles.cardsframe}>
          <div className={styles.rightclmn}>
            <SystemChangeCard
              old="People owned a house."
              new1="Communities own properties."
              explanation="Once, individual homeownership was the norm. Today, through the power of tokenization, communities collectively own properties. This evolution, facilitated by blockchain technology, promotes shared responsibility and democratizes access to real estate, reshaping the traditional notions of property ownership in the modern era."
              systemChangeCardBorder="1px solid #000"
            />
            <SystemChangeCard
              old="The rich had access to real-estate investments."
              new1="Anyone can invest in real-estate."
              explanation="Historically, real estate investment was a privilege reserved for the affluent. Today, that paradigm has shifted, granting access to all. By democratizing real estate investments, we're breaking down economic barriers and empowering individuals from all walks of life to tap into the lucrative world of property assets."
              systemChangeCardBorder="1px solid #000"
            />
          </div>
          <div className={styles.rightclmn}>
            <SystemChangeCard
              old="Property owners paid off debt to the bank."
              new1="Property members invest collectively."
              explanation="Gone are the days when owners merely paid off debt. Now, community members can allocate a portion of their monthly fee towards investing in a diversified real-estate portfolio, turning regular payments into potential growth opportunities."
              systemChangeCardBorder="1px solid #000"
            />
            <SystemChangeCard
              old="A new generation couldn't afford to live in the city."
              new1="Now they can."
              explanation="The urban dream has often eluded younger generations due to prohibitive costs. Our innovative solution bridges this gap, ensuring they no longer have to compromise on city living. Through our approach, vibrant urban lifestyles become accessible and affordable, redefining what's possible for the youth of today."
              systemChangeCardBorder="1px solid #000"
            />
          </div>
        </div>
        <div className={styles.cardsframe4}>
          <div className={styles.logowyckx}>
            <img className={styles.logoIcon} alt="" src="/logo2.svg" />
          </div>
        </div>
      </div>
      <Quote
        quote="Here’s to the WYCKED ones, the misfits, the rebels — the ones who see things differently ... because the ones who are crazy enough to think that they can change the world, are the ones who do."
        author="IN REFERENCE TO STEVE JOBS"
      />
      <Footer />
    </div>
  );
};

export default Solution;
