import { FunctionComponent, useMemo, type CSSProperties } from "react";
import styles from "./SystemChangeCard.module.css";

type SystemChangeCardType = {
  old?: string;
  new1?: string;
  explanation?: string;

  /** Style props */
  systemChangeCardBorder?: CSSProperties["border"];
};

const SystemChangeCard: FunctionComponent<SystemChangeCardType> = ({
  old = "People used to",
  new1 = "Communities own",
  explanation = "Hello world.",
  systemChangeCardBorder,
}) => {
  const systemChangeCardStyle: CSSProperties = useMemo(() => {
    return {
      border: systemChangeCardBorder,
    };
  }, [systemChangeCardBorder]);

  return (
    <div className={styles.systemchangecard} style={systemChangeCardStyle}>
      <div className={styles.old}>
        <div className={styles.labelold}>
          <b className={styles.old1}>OLD</b>
        </div>
        <b className={styles.peopleUsedTo}>{old}</b>
      </div>
      <div className={styles.new}>
        <div className={styles.labelnew}>
          <div className={styles.label}>
            <b className={styles.old1}>NEW</b>
          </div>
        </div>
        <b className={styles.communitiesOwn}>{new1}</b>
        <div className={styles.helloWorld}>{explanation}</div>
      </div>
    </div>
  );
};

export default SystemChangeCard;
