import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import Solution from "./pages/Solution";
import Property from "./pages/Property";
import Platform from "./pages/Platform";
import { useEffect } from "react";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/solution":
        title = "WYCKX | Next-gen real-estate";
        metaDescription =
          "What if we can build an affordable and green urban property for a new generation. Because we can. No cap.";
        break;
      case "/property":
        title = "";
        metaDescription = "";
        break;
      case "/platform":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/solution" element={<Solution />} />
      <Route path="/property" element={<Property />} />
      <Route path="/platform" element={<Platform />} />
    </Routes>
  );
}
export default App;
