import { FunctionComponent } from "react";
import styles from "./Quote.module.css";

type QuoteType = {
  quote?: string;
  author?: string;
};

const Quote: FunctionComponent<QuoteType> = ({
  quote = "Here’s to the WYCKED ones, the misfits, the rebels — the ones who see things differently ... because the ones who are crazy enough to think that they can change the world, are the ones who do.",
  author = "IN REFERENCE TO STEVE JOBS",
}) => {
  return (
    <div className={styles.quote}>
      <img
        className={styles.ribbonspacerIcon}
        alt=""
        src="/ribbonspacer@2x.png"
      />
      <div className={styles.quotecontent}>
        <div className={styles.heresToThe}>{quote}</div>
        <div className={styles.quotecontentChild} />
        <b className={styles.inReferenceTo}>{author}</b>
        <div className={styles.quotecontent1}>
          <div className={styles.logowyckx}>
            <img className={styles.logoIcon} alt="" src="/logo.svg" />
          </div>
          <div className={styles.weAreConvinced}>
            We are convinced that the realms of property development, home
            financing, and community living are ready for a transformative
            redesign—one that seamlessly integrates our deepening
            interconnectedness with cutting-edge technology.
          </div>
        </div>
      </div>
      <img
        className={styles.ribbonspacerIcon}
        alt=""
        src="/ribbonspacer1@2x.png"
      />
    </div>
  );
};

export default Quote;
