import { FunctionComponent } from "react";
import NavigationContainer from "../components/NavigationContainer";
import Card from "../components/Card";
import Quote from "../components/Quote";
import Footer from "../components/Footer";
import styles from "./Platform.module.css";

const Platform: FunctionComponent = () => {
  return (
    <div className={styles.platform}>
      <NavigationContainer
        icon="/icon.svg"
        vector="/vector.svg"
        navigationContainerFlexShrink="0"
        ourSolutionColor="#fff"
        ourPlatformColor="#fff"
        ourPropertyColor="#fff"
        backgroundStyle="#111"
      />
      <div className={styles.header}>
        <div className={styles.herocontainer}>
          <div className={styles.herocontent}>
            <b className={styles.ourPlatform}>our platform</b>
            <div className={styles.theUpcomingGeneration}>
              Many in the newer generations feel that property ownership is reserved for the privileged few. Let's shift that perspective. Living affordably in the city and owning property assets is within reach – even if it's just a fraction.
            </div>
          </div>
          <div className={styles.herovisual} />
        </div>
        <img
          className={styles.ribbonspacerIcon}
          alt=""
          src="/ribbonspacer2@2x.png"
        />
      </div>
      <div className={styles.cards}>
        <section className={styles.cardsframe}>
          <div className={styles.clmn}>
            <Card
              icon="/icon.svg"
              cardTitle="No rent or sale. Invest."
              cardContent="Whatever you think, think the opposite. Next-gen resident members pay for urban living and simultaneously invest in the WYCKX real-estate platform through affordable monthly fees."
              showIcon
              showCommunityEconomics
              showInTheEra
              showButton
              cardBackgroundColor="#111"
              communityEconomicsColor="#fff"
              inTheEraColor="#fff"
            />
          </div>
          <div className={styles.clmn1}>
            <Card
              icon="/icon1.svg"
              cardTitle="Wealth accumulation."
              cardContent="By investing a piece of their monthly fees in the WYCKX real-estate platform, next-gen resident members accumulate wealth and gain access to owning pieces of future real-estate instead of paying off debt or simply wasting money on rent."
              showIcon
              showCommunityEconomics
              showInTheEra
              showButton={false}
              cardBackgroundColor="#fff"
              communityEconomicsColor="#000"
              inTheEraColor="#000"
            />
          </div>
        </section>
        <img
          className={styles.cardsframeIcon}
          alt=""
          src="/cardsframe4@2x.png"
        />
        <section className={styles.cardsframe}>
          <div className={styles.clmn}>
            <Card
              icon="/icon.svg"
              cardTitle="Tokenized assets."
              cardContent="All WYCKX properties are translated into a representative set of digital collectibles. These Web3 collectibles reflect the value of the property, proof an investor’s stake in the game, are stakable and tradable on the open market."
              showIcon
              showCommunityEconomics
              showInTheEra
              showButton={false}
              cardBackgroundColor="#111"
              communityEconomicsColor="#fff"
              inTheEraColor="#fff"
            />
          </div>
          <div className={styles.clmn1}>
            <Card
              icon="/icon1.svg"
              cardTitle="Property Development."
              cardContent="The upcoming generation is deeply committed to a healthier planet. WYCKX stands with this forward-thinking cohort, proactively shaping the future. We construct eco-friendly properties and motivate the new wave of inhabitants to lead the way in sustainable urban lifestyles."
              showIcon={false}
              showCommunityEconomics={false}
              showInTheEra={false}
              showButton
              cardBackgroundColor="#111"
              communityEconomicsColor="#000"
              inTheEraColor="#000"
            />
          </div>
        </section>
        <img
          className={styles.cardsframeIcon}
          alt=""
          src="/cardsframe5@2x.png"
        />
      </div>
      <Quote
        quote="Here’s to the WYCKED ones, the misfits, the rebels — the ones who see things differently ... because the ones who are crazy enough to think that they can change the world, are the ones who do."
        author="IN REFERENCE TO STEVE JOBS"
      />
      <Footer />
    </div>
  );
};

export default Platform;
