import { FunctionComponent } from "react";
import { useNavigate } from 'react-router-dom';
import styles from "./Footer.module.css";

const Footer: FunctionComponent = () => {

  const navigate = useNavigate();

  return (
    <div className={styles.footer}>
      <div className={styles.herocontent}>
        <div className={styles.clmn02}>
          <div className={styles.weBelieveThat}>
            {" "}
            We believe that the concept of tokenization, with emerging Security
            Token Offerings and the opportunities presented by blockchain in
            exchanging value digitally as easily as information, represents
            another major leap forward in our global economy.
          </div>
        </div>
        <div className={styles.clmn01}>
          <button onClick={() => { navigate('/solution') }}>
            <b className={styles.ourSolution}><span style={{paddingRight: '10px'}}>* /</span> our solution</b>
          </button>
          <button onClick={() => { navigate('/platform') }}>
            <b className={styles.ourSolution}><span style={{paddingRight: '10px'}}>* /</span> our PLATFORM</b>
          </button>
          <button onClick={() => { navigate('/property') }}>
            <b className={styles.ourSolution}><span style={{paddingRight: '10px'}}>* /</span> our PROPERTY</b>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Footer;
