import { FunctionComponent } from "react";
import NavigationContainer from "../components/NavigationContainer";
import Button from "../components/Button";
import Card from "../components/Card";
import Quote from "../components/Quote";
import Footer from "../components/Footer";
import styles from "./Home.module.css";

const Home: FunctionComponent = () => {
  return (
    <div className={styles.home}>
      <NavigationContainer
        icon="/icon1.svg"
        vector="/vector1.svg"
        navigationContainerFlexShrink="0"
        ourSolutionColor="#000"
        ourPlatformColor="#000"
        ourPropertyColor="#000"
      />
      <div className={styles.header}>
        <div className={styles.herocontainer}>
          <div className={styles.herocontent}>
            <div className={styles.herotitles}>
              <div className={styles.logowyckx}>
                <img className={styles.logoIcon} alt="" src="/logo3.svg" />
              </div>
              <b className={styles.herocaption}>
                Changing the real-estate game for next-gen urban citizens to drive affordable and green living.
              </b>
            </div>
            <div className={styles.metricsbox}>
              <div className={styles.clmn01}>
                <b className={styles.k}>300K</b>
                <b className={styles.tokenSupply}>TOKEN SUPPLY</b>
              </div>
              <div className={styles.clmn01}>
                <b className={styles.k}>5M</b>
                <b className={styles.tokenSupply}>INVESTMENT</b>
              </div>
              <div className={styles.clmn03}>
                <b className={styles.k}>5%</b>
                <b className={styles.tokenSupply}>ANNUAL RETURNS</b>
              </div>
            </div>
          </div>
        </div>
        <img
          className={styles.ribbonspacerIcon}
          alt=""
          src="/ribbonspacer2@2x.png"
        />
      </div>
      <div className={styles.cards}>
        <section className={styles.cardsframe}>
          <div className={styles.clmn}>
            <Card
              icon="/icon.svg"
              cardTitle="Community Economics."
              cardContent="In the era of blockchain, the concept of community ownership has gained unprecedented momentum. Blockchain, often synonymous with decentralization, offers a transparent, immutable, and secure foundation, challenging traditional models of ownership and governance."
              showIcon
              showCommunityEconomics
              showInTheEra
              showButton
              cardBackgroundColor="#111"
              communityEconomicsColor="#fff"
              inTheEraColor="#fff"
            />
          </div>
          <div className={styles.clmn1}>
            <Card
              icon="/icon1.svg"
              cardTitle="Property Development."
              cardContent="The upcoming generation is deeply committed to a healthier planet. WYCKX stands with this forward-thinking cohort, proactively shaping the future. We construct eco-friendly properties and motivate the new wave of inhabitants to lead the way in sustainable urban lifestyles."
              showIcon
              showCommunityEconomics
              showInTheEra
              showButton={false}
              cardBackgroundColor="#fff"
              communityEconomicsColor="#000"
              inTheEraColor="#000"
            />
          </div>
        </section>
        <div className={styles.cardsframe1}>
          <div className={styles.logowyckx1}>
            <img className={styles.logoIcon1} alt="" src="/logo1.svg" />
          </div>
        </div>
        <section className={styles.cardsframe}>
          <div className={styles.clmn}>
            <Card
              icon="/icon.svg"
              cardTitle="Fractional Ownership."
              cardContent="Many in the newer generations feel that property ownership is reserved for the privileged few. Let's shift that perspective. Owning property is within reach – even if it's just a fraction. Enabled by blockchain."
              showIcon
              showCommunityEconomics
              showInTheEra
              showButton={false}
              cardBackgroundColor="#e08f7c"
              communityEconomicsColor="#fff"
              inTheEraColor="#fff"
            />
          </div>
          <div className={styles.clmn1}>
            <Card
              icon="/icon1.svg"
              cardTitle="Property Development."
              cardContent="The upcoming generation is deeply committed to a healthier planet. WYCKX stands with this forward-thinking cohort, proactively shaping the future. We construct eco-friendly properties and motivate the new wave of inhabitants to lead the way in sustainable urban lifestyles."
              showIcon={false}
              showCommunityEconomics={false}
              showInTheEra={false}
              showButton
              cardBackgroundColor="#111"
              communityEconomicsColor="#000"
              inTheEraColor="#000"
            />
          </div>
        </section>
        <img
          className={styles.cardsframeIcon}
          alt=""
          src="/cardsframe2@2x.png"
        />
      </div>
      <Quote
        quote="Here’s to the WYCKED ones, the misfits, the rebels — the ones who see things differently ... because the ones who are crazy enough to think that they can change the world, are the ones who do."
        author="IN REFERENCE TO STEVE JOBS"
      />
      <Footer />
    </div>
  );
};

export default Home;
